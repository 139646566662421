var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  isDebug: true,
  toggle: false,
  toggleChartOfAccounts: false,
  toggleGeneralLedger: false,
  toggleBalanceSheet: false,
  toggleProfitAndLoss: false,
  toggleArDelinquencySummary: false,
  toggleArDelinquencyDetail: false,
  toggleApSummary: false,
  toggleApDetail: false,
  toggleCashFlow: false,
  loading: false,
  selectedReport: null,
  selectedUserReport: null,
  userReport: 0,
  filtered: '',
  reportCategory: null,
  reportCategoryName: '',
  reportTableList: [],
  reportCategoryList: [],
  savedReportList: [],
  cols: 2,
  styles: {
    cardWidth: {
      width: '400px'
    },
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})
