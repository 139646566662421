<template>
  <div class="section">
    <div class="container">
      <div class="is-size-4" v-if="report && report != undefined">
        {{ `${report.name} Report` }}
      </div>
      <div class="is-size-4" v-else>View Report</div>
      <div class="box is-12" style="overflow-y: auto;">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="column is-6" v-if="dateOneLabel">
                  <b-field :label="`${dateOneLabel}`">
                    <b-datepicker
                      v-model="formData.dateOne"
                      :label="`${dateOneLabel}`"
                      :show-week-number="showWeekNumber"
                      :placeholder="`Click to select ${dateOneLabel}...`"
                      icon="calendar-today"
                      trap-focus
                      editable
                      required
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="column is-6" v-if="dateTwoLabel">
                  <b-field :label="`${dateTwoLabel}`">
                    <b-datepicker
                      v-model="formData.dateTwo"
                      :label="`${dateTwoLabel}`"
                      :show-week-number="showWeekNumber"
                      :placeholder="`Click to select ${dateTwoLabel}...`"
                      icon="calendar-today"
                      trap-focus
                      editable
                      required
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="column is-6" v-if="monthOneLabel">
                  <b-field :label="`${monthOneLabel}`">
                    <b-datepicker
                      v-model="formData.monthOne"
                      :label="`${monthOneLabel}`"
                      :placeholder="`Click to select ${monthOneLabel}...`"
                      icon="calendar-today"
                      trap-focus
                      editable
                      required
                      type="month"
                    ></b-datepicker>
                  </b-field>
                </div>
                <div class="column is-6" v-if="monthTwoLabel">
                  <b-field :label="`${monthTwoLabel}`">
                    <b-datepicker
                      v-model="formData.monthTwo"
                      :label="`${monthTwoLabel}`"
                      :placeholder="`Click to select ${monthTwoLabel}...`"
                      icon="calendar-today"
                      trap-focus
                      editable
                      required
                      type="month"
                    ></b-datepicker>
                  </b-field>
                </div>
                <div
                  class="column is-8"
                  v-if="dropDownList !== undefined && dropDownList && dropDownList.length > 0"
                >
                  <valid-select
                    :label="dropDownLabel"
                    :placeholder="dropDownLabel"
                    vid="sortBy"
                    v-model="formData.dropDownOne"
                    aria-required="The Bank Account Type is required"
                    rules="required"
                  >
                    <option
                      v-for="option in dropDownList"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </div>
                <div class="column is-12">
                  <b-checkbox class="has-left-text" v-model="formData.saveWithRun"
                    >Save Report with Run</b-checkbox
                  >
                </div>
                <div class="pt-5 pl-3">
                  <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                    {{ buttonText }}
                  </button>
                </div>
                <div class="pb-6"></div>
                <div class="pb-6"></div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { ValidationError } from '@/services/errors'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { notifyError } from '@/services/notify'
import { base64toBlob, showPdf } from '@/services/blobutility'
import $ from 'jquery'
import ValidSelect from '@/components/inputs/ValidSelect'
import { metaReport } from '@/services/Reports/RunReport/Meta/store'
import { reportList } from '@/services/Reports/store'
import { isNumeric } from '@/utilities/validate/isNumeric'
import _get from 'lodash/get'

import moment from 'moment'
const today = moment()

let DEFAULT_FORM_VALUES = {
  saveWithRun: false,
  dateOne: null,
  dateTwo: null,
  monthOne: null,
  monthTwo: null,
  dropDownOne: null
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'MetaReportModal',

  props: {
    report: Object
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    ValidSelect
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      selectedRow: null,
      buttonText: 'Complete',
      showWeekNumber: false,
      dropDownLabel: '',
      dropDownList: [],
      filteredReportMeta: null,
      paramNameValues: [],
      dateOneLabel: '',
      dateTwoLabel: '',
      monthOneLabel: null,
      monthTwoLabel: null,
      dateSingleLabel: '',
      saveUrl: '',
      baseSixtyFourUrl: ''
    }
  },

  async created() {
    await this.getReportMetaData()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  mounted() {
    this.loading = true

    if (this.report && this.report.reportID != undefined) {
      if (this.isDebug == true) console.debug('this.report=' + JSON.stringify(this.report))

      this.buttonText = 'Run Report'
    }

    this.formData.monthOne = new Date()
    this.formData.monthTwo = new Date()

    this.loading = false
  },

  methods: {
    closeModal() {
      this.$emit('update:toggle', (this.completeToggle = !this.completeToggle))

      // auto-close modal
      $('#clickclose').click()
    },

    blankPage() {
      let route = this.$router.resolve({
        path:
          '/runreport/' +
          this.report.codeName +
          '/' +
          this.report.name +
          '/' +
          this.formData.saveWithRun
      })
      window.open(route.href, '_blank')
    },

    async getReportMetaData() {
      await reportList
        .dispatch('getReportsMetadata', {
          hoaID: this.hoaId
        })
        .then(async ({ metadata }) => {
          if (metadata) {
            console.debug('metadata=' + JSON.stringify(metadata))

            const retrievedReport = metadata.reports.filter(
              f => f.reportCode === this.report.reportCode
            )

            console.debug(
              'REPORT=' +
                JSON.stringify(this.report) +
                '-retrievedReport=' +
                JSON.stringify(retrievedReport)
            )

            if (retrievedReport && retrievedReport[0]) {
              this.saveUrl = this.report.saveURL
              this.baseSixtyFourUrl = this.report.getBase64URL

              const parameters = retrievedReport[0].parameters

              if (parameters !== undefined && parameters) {
                parameters.forEach(parameter => {
                  if (parameter) {
                    //Hidden Variables for endpoints
                    if (
                      parameter.dataType === 'hidden' &&
                      parameter.fixedValue !== undefined &&
                      parameter.fixedValue &&
                      parameter.queryParameterName !== undefined &&
                      parameter.queryParameterName
                    ) {
                      this.paramNameValues.push({
                        name: parameter.queryParameterName,
                        value: parameter.fixedValue,
                        type: 'hidden'
                      })
                    }
                    //Drop down list
                    else if (
                      parameter.dataType === 'enumeration' &&
                      parameter.options !== undefined &&
                      parameter.options
                    ) {
                      const metaOptions = parameter.options
                      const options = metaOptions.map(m => ({
                        value: m,
                        label: m
                      }))

                      if (options) {
                        console.debug('options=' + JSON.stringify(options))
                        this.dropDownList = options

                        this.dropDownLabel = parameter.displayName

                        this.formData.dropDownOne = parameter.defaultValue

                        this.paramNameValues.push({
                          name: parameter.queryParameterName,
                          value: parameter.defaultValue,
                          type: 'enumeration'
                        }) //value will be populated on submit
                      }
                    }
                    //Drop down list (Name/Value pair)
                    else if (
                      parameter.dataType === 'enumeration-name-value' &&
                      parameter.options !== undefined &&
                      parameter.options
                    ) {
                      const metaOptions = parameter.options
                      const options = metaOptions.map(m => ({
                        value: m.value,
                        label: m.name
                      }))

                      if (options) {
                        console.debug('options=' + JSON.stringify(options))
                        this.dropDownList = options

                        this.dropDownLabel = parameter.displayName

                        this.formData.dropDownOne = parameter.defaultValue

                        this.paramNameValues.push({
                          name: parameter.queryParameterName,
                          value: parameter.defaultValue,
                          type: 'enumeration'
                        }) //value will be populated on submit
                      }
                    }
                    //Date Range
                    else if (parameter.dataType === 'date-range') {
                      this.dateOneLabel = _get(parameter, 'startDateDisplayName', '')
                      this.dateTwoLabel = _get(parameter, 'endDateDisplayName', '')

                      this.formData.dateOne = new Date(
                        moment(parameter.startDateDefaultValue).format('MM/DD/YYYY')
                      )
                      this.formData.dateTwo = new Date(
                        moment(parameter.endDateDefaultValue).format('MM/DD/YYYY')
                      )

                      this.paramNameValues.push({
                        name: parameter.startDateQueryParameterName,
                        value: parameter.startDateDefaultValue,
                        type: 'date1'
                      })
                      this.paramNameValues.push({
                        name: parameter.endDateQueryParameterName,
                        value: parameter.endDateDefaultValue,
                        type: 'date2'
                      })
                    } else if (parameter.dataType === 'month-range') {
                      this.monthOneLabel = _get(parameter, 'startMonthDisplayName', '')
                      this.monthTwoLabel = _get(parameter, 'endMonthDisplayName', '')

                      console.debug(
                        'month default=' +
                          moment(parameter.startMonthDefaultValue).format(
                            `${today.month()}/01/${today.year()}`
                          )
                      )

                      this.formData.monthOne = new Date(
                        moment(parameter.startMonthDefaultValue).format(
                          `${today.month() + 1}/01/${today.year()}`
                        )
                      )
                      this.formData.monthTwo = new Date(
                        moment(parameter.startMonthDefaultValue).format(
                          `${today.month() + 1}/01/${today.year()}`
                        )
                      )

                      this.paramNameValues.push({
                        name: parameter.startMonthQueryParameterName,
                        value: parameter.startMonthDefaultValue,
                        type: 'month1'
                      })
                      this.paramNameValues.push({
                        name: parameter.endMonthQueryParameterName,
                        value: parameter.endMonthDefaultValue,
                        type: 'month2'
                      })
                    } else if (
                      parameter.dataType === 'month' &&
                      parameter.datatype !== 'month-range'
                    ) {
                      this.monthOneLabel = ''
                      this.monthTwoLabel = _get(parameter, 'displayName', '')
                      this.formData.monthTwo = new Date(
                        moment(parameter.startMonthDefaultValue).format(
                          `${today.month() + 1}/01/${today.year()}`
                        )
                      )
                      this.paramNameValues.push({
                        name: parameter.queryParameterName,
                        value: parameter.defaultValue,
                        type: 'month2'
                      })
                    }
                    //Date
                    else if (parameter.dataType === 'date') {
                      if (this.dateOneLabel === '') {
                        this.dateOneLabel = _get(parameter, 'displayName', '')
                        this.formData.dateOne = new Date(
                          moment(parameter.defaultValue).format('MM/DD/YYYY')
                        )
                        this.paramNameValues.push({
                          name: parameter.queryParameterName,
                          value: parameter.defaultValue,
                          type: 'date1'
                        })
                      } else {
                        this.dateTwoLabel = _get(parameter, 'displayName', '')
                        this.formData.dateTwo = new Date(
                          moment(parameter.defaultValue).format('MM/DD/YYYY')
                        )
                        this.paramNameValues.push({
                          name: parameter.queryParameterName,
                          value: parameter.defaultValue,
                          type: 'date2'
                        })
                      }
                    }
                  }
                })
              }
            }
          }
        })
    },

    async onFormSubmit() {
      if (!this.formData || this.formData == undefined) {
        notifyError('There was a problem running this report.')
        return
      }

      this.loadReport()

      this.loading = false
    },

    refreshRedirect() {
      let route = this.$router.resolve({
        path: '/reports/all'
      })
      window.open(route.href, '_blank')
    },

    getJsonPayload() {
      console.debug('in getJsonPayload=' + this.formData.monthOne)

      let payload = `"format":"PDF"`
      if (this.paramNameValues && this.paramNameValues.length > 0) {
        this.paramNameValues.forEach(f => {
          if (f) {
            if (f.type.indexOf('date') > -1) {
              if (f.type === 'date1') {
                f.value = this.formData.dateOne
              } else if (f.type === 'date2') {
                f.value = this.formData.dateTwo
              }

              f.value = moment(f.value).format('YYYY-MM-DD')
            } else if (f.dataType === 'month') {
              if (f.type === 'month2') {
                f.value = moment(this.formData.monthTwo).format('MM')
              }
            } else if (f.dataType === 'month-range') {
              console.debug('month-range...')
              if (f.type === 'month1') {
                f.value = moment(this.formData.monthOne).format('MM')
                console.debug('f value for month1=' + this.formData.monthOne)
              } else if (f.type === 'month2') {
                f.value = moment(this.formData.monthTwo).format('MM')
              }
            } else if (f.type === 'enumeration') {
              f.value = this.formData.dropDownOne
            }

            if (isNumeric({ n: f.value }) == false) {
              f.value = `"${f.value}"`
            }

            payload += `,"${f.name}":${f.value}`
          }
        })

        payload = '{' + payload + '}'
      }

      console.debug('payload==' + payload)

      return JSON.parse(payload)
    },

    getParametersPayload() {
      let payload = `?format=PDF`
      if (this.paramNameValues && this.paramNameValues.length > 0) {
        this.paramNameValues.forEach(f => {
          if (f) {
            if (f.type.indexOf('date') > -1) {
              if (f.type === 'date1') {
                f.value = this.formData.dateOne
              } else if (f.type === 'date2') {
                f.value = this.formData.dateTwo
              }

              f.value = moment(f.value).format('YYYY-MM-DD')
            } else if (f.type.indexOf('month') > -1) {
              if (f.type === 'month1') {
                const month = moment(this.formData.monthOne).month() + 1
                const year = moment(this.formData.monthOne).year()
                f.value = year + '-' + month
              } else if (f.type === 'month2') {
                const month = moment(this.formData.monthTwo).month() + 1
                const year = moment(this.formData.monthTwo).year()
                f.value = year + '-' + month
              }
            } else if (f.type === 'enumeration') {
              f.value = this.formData.dropDownOne
            } else {
              console.debug('parameter else...' + JSON.stringify(f))
            }

            payload += `&${f.name}=${f.value}`
          }
        })
      }

      return payload
    },

    async loadReport() {
      if (!this.formData || this.formData == undefined) {
        notifyError('There was a problem retrieving this report.')
        return
      }

      this.loading = true

      if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      try {
        const params = this.getParametersPayload()
        console.debug('params=' + params)

        const fullSaveUrl = this.saveUrl + params
        const fullBaseSixtyFourUrl = this.baseSixtyFourUrl + params

        if (this.formData.saveWithRun == true && this.saveUrl && this.baseSixtyFourUrl) {
          if (this.isDebug == true) console.debug('in save loadReport...')

          await metaReport
            .dispatch('runMetaReportAndSave', { pathWithParams: fullSaveUrl })
            .then(async ({ userreportdto }) => {
              if (userreportdto && userreportdto != undefined) {
                if (this.isDebug == true)
                  console.debug('userreportdto=' + JSON.stringify(userreportdto))

                await metaReport
                  .dispatch('runMetaBase64Report', { pathWithParams: fullBaseSixtyFourUrl })
                  .then(({ returned }) => {
                    loadingComponent.close()

                    if (returned && returned != undefined) {
                      if (this.isDebug == true)
                        console.debug('base64pdf........................' + returned)

                      const contentType = 'application/pdf'

                      const blob = base64toBlob(returned, contentType)

                      showPdf(blob)
                    }
                    return
                  })
              }
            })
        } else {
          if (this.isDebug == true) console.debug('in loadReport...' + this.baseSixtyFourUrl)
          await metaReport
            .dispatch('runMetaBase64Report', { pathWithParams: fullBaseSixtyFourUrl })
            .then(({ returned }) => {
              loadingComponent.close()

              if (returned && returned != undefined) {
                if (this.isDebug == true)
                  console.debug('base64pdf........................' + returned)

                const contentType = 'application/pdf'
                const blob = base64toBlob(returned, contentType)

                showPdf(blob)
              }
              return
            })
        }
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem running this report.' + e)
      }

      loadingComponent.close()

      this.loading = false
    }
  }
}
</script>

<style lang="scss">
#meta-report-modal .step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

#meta-report-modal .b-steps.is-small .steps {
  margin-top: 1rem;
}

#meta-report-modal .b-steps .steps + .step-content {
  padding-top: 0 !important;
}

#meta-report-modal .step-navigation {
  display: flex;
  justify-content: flex-end;
}

#meta-report-modal .my-grid-class {
  height: 400px;
}

#meta-report-modal tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
