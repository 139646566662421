<template>
  <PageContentLayoutOnly>
    <div>
      <h3 :style="{ display: 'inline-block' }">
        {{ `${$t('report.title')}` }}
      </h3>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'

export default {
  name: 'ReportsAll',
  components: {
    PageContentLayoutOnly
  },

  mounted() {
    this.$router.push({
      path: '/reports/all'
    })
  },

  i18n: {
    messages: {
      en: { report: { title: 'Reports' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

@media screen and (max-width: $tablet) {
  .filter-button {
    display: none;
  }
}

div.amountFrom {
  width: 60px !important;
}

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}
</style>
