import { reportList } from '@/services/Reports/store'
import moment from 'moment'
import { notifyError } from '@/services/notify'
import { base64toBlob, showPdf } from '@/services/blobutility'
import _sortBy from 'lodash/sortBy'

export const methods = {
  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  determineReportRows(list) {
    if (this.isDebug == true) console.debug('determineReportRows()...' + JSON.stringify(list))

    if (list && list != undefined) {
      this.reportTableList = list.map(m => ({
        ...m,
        savedReports: this.savedReportList.filter(
          f => f.codeName == m.reportCode || f.codeName == 'default'
        )
      }))
    } else {
      if (this.isDebug == true) console.debug('else determineReportRows...')
    }
  },

  determineReportCategoryList(metadata) {
    if (metadata && metadata != undefined) {
      if (this.isDebug == true)
        console.debug(
          'this.filtered=' +
            this.filtered +
            ', determineReportCategoryList=' +
            JSON.stringify(metadata.categories)
        )

      if (this.filtered && this.filtered != undefined && this.filtered == 'financial') {
        this.reportCategoryList = metadata.categories
          .map(v => ({
            value: v.categoryCode,
            label: v.categoryName ? v.categoryName : '',
            categoryType: v.categoryType
          }))
          .filter(f => f.label.length > 0 && f.value.length > 0 && f.categoryType == 'Financial')
          .sort((a, b) => a.label - b.label)

        this.reportCategoryList = _sortBy(this.reportCategoryList, 'label')
      } else {
        this.reportCategoryList = metadata.categories
          .map(v => ({
            value: v.categoryCode,
            label: v.categoryName ? v.categoryName : ''
          }))
          .filter(f => f.label.length > 0 && f.value.length > 0)
          .sort((a, b) => a.label - b.label)

        this.reportCategoryList = _sortBy(this.reportCategoryList, 'label')
      }

      //select first if present
      if (
        this.reportCategoryList &&
        this.reportCategoryList != undefined &&
        this.reportCategoryList[0] &&
        this.reportCategoryList[0] != undefined
      )
        this.reportCategory = this.reportCategoryList[0]
    } else {
      if (this.isDebug == true) console.debug('else determineReportCategoryList...')
    }
  },

  async loadReportsByCategory(reportCategory) {
    if (this.isDebug == true) console.debug('reportCategoryID=' + JSON.stringify(reportCategory))

    if (reportCategory && reportCategory != undefined) {
      this.reportCategoryName = reportCategory.label
      await reportList
        .dispatch('getReportsMetadata', {
          hoaID: this.hoaId
        })
        .then(({ metadata }) => {
          let reportCategoryDropDown = metadata.reports.filter(
            f => f.categoryCode == reportCategory.value
          )
          this.determineReportRows(reportCategoryDropDown)
        })
    }
  },

  async reload() {
    this.loading = true

    try {
      await this.loadUserReportList()

      await reportList
        .dispatch('getReportsMetadata', {
          hoaID: this.hoaId
        })
        .then(({ metadata }) => {
          if (metadata && metadata != undefined) {
            this.determineReportCategoryList(metadata)
          }
        })
    } catch (e) {
      console.debug('error in method for retrieving reports:' + e)
    }

    this.loading = false
  },

  async reloadSavedReportList() {
    this.loading = true
    await this.loadUserReportList()
    this.reportTableList = this.reportTableList.map(m => ({
      ...m,
      savedReports: this.savedReportList.filter(
        f => f.codeName == m.reportCode || f.codeName == 'default'
      )
    }))
    this.loading = false
  },

  async loadUserReportList() {
    await reportList.dispatch('getUserReportListForUser').then(({ list }) => {
      if (list && list != null) {
        this.savedReportList = list.map(m => ({
          value: m.userReportID,
          label: `${m.reportName} - ${moment(m.createdDate).format('MM/DD/YYYY hh:mm:ss a')}`,
          reportName: m.reportName,
          codeName: m.codeName
        }))

        if (this.isDebug == true)
          console.debug('loadUserReportList(): ' + JSON.stringify(this.savedReportList))

        if (this.reportTableList && this.savedReportList) {
          if (this.reportTableList.savedReports && this.reportTableList.savedReports != undefined) {
            Array.from(this.reportTableList).forEach(element => {
              if (
                element &&
                element != undefined &&
                element.savedReports &&
                element.savedReports != undefined
              ) {
                element.savedReports = null
              }
            })
          }

          this.savedReportList.unshift({
            value: '',
            label: '<-Select Saved Report->',
            reportName: 'select',
            codeName: 'default'
          })
          if (this.isDebug == true)
            console.debug('this.savedReportList.unshift=' + JSON.stringify(this.savedReportList))

          this.reportTableList = this.reportTableList.map(m => ({
            ...m,
            savedReports: this.savedReportList.filter(
              f => f.codeName == m.codeName || f.codename == 'default'
            )
          }))

          if (this.reportTableList && this.savedReportList) {
            if (
              this.reportTableList.savedReports &&
              this.reportTableList.savedReports != undefined
            ) {
              Array.from(this.reportTableList).forEach(element => {
                if (
                  element &&
                  element != undefined &&
                  element.savedReports &&
                  element.savedReports != undefined
                ) {
                  element.savedReports.unshift({
                    value: '',
                    label: '<-Select Saved Report->',
                    reportName: 'select',
                    codeName: 'default'
                  })
                  this.userReport = element.savedReports.filter(f => f.codeName == 'default')
                }
              })
            }

            //Select default select label for each saved report
            this.userReport = this.savedReportList.filter(f => f.codeName == 'default')[0]
          }
        }
      }
    })
  },

  processReport(report) {
    if (this.isDebug == true) console.debug('reportID=' + report.reportID)
    //this.userReportID = 0

    this.toggle = true

    this.selectedReport = report
    this.selectedUserReport = null
  },

  processSavedReport(userReport) {
    this.selectedReport = null

    if (!userReport) {
      notifyError('Please select a saved report')
    }
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (this.isDebug == true) console.debug('userReport=' + JSON.stringify(userReport))
    this.loadUserSavedReport(userReport.value).then(() => {
      loadingComponent.close()
    })
  },

  blankPage(userReportID, reportName) {
    let re = /\//gi
    let escapedReportName = reportName.replace(re, '%2F')
    let route = this.$router.resolve({
      path: '/savedreport/' + userReportID + '/' + escapedReportName
    })
    window.open(route.href, '_blank')
  },

  refreshRedirect() {
    let route = this.$router.resolve({
      path: '/reports/all'
    })
    window.open(route.href, '_blank')
  },

  async loadUserSavedReport(userReportID) {
    if (!userReportID || userReportID == undefined || userReportID <= 0) {
      notifyError('There was a problem retrieving this saved report.')
      return
    }

    try {
      await reportList
        .dispatch('runUserReportBase64Report', {
          userReportID: userReportID
        })
        .then(({ returned }) => {
          if (returned && returned != undefined) {
            if (this.isDebug == true) console.debug('base64pdf........................' + returned)

            const contentType = 'application/pdf'
            const blob = base64toBlob(returned, contentType)

            showPdf(blob)
          }

          return
        })
    } catch (e) {
      console.debug(e.message)
      notifyError('There was a problem retrieving this saved report.')
    }
    this.loading = false
  }
}
