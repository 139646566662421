/*
  import { reportList } from '@/services/Reports/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async getReportsMetadata({}, { hoaID }) {
    console.log('in getReportMetadata...')
    try {
      const result = await kms.get('/ReportManagement/ReportMetadata', {
        params: {
          hoaID
        }
      })

      return {
        metadata: result
      }
    } catch (exception) {
      notifyProblem(`The reports metadata could not be retrieved.`)
      console.error(exception)
    }

    return {
      metadata: []
    }
  },

  async getUserReportListForUser({}) {
    console.log('in getUserReportListForUser...')
    try {
      const result = await kms.get('/ReportManagement/UserReport/List')

      if (isDebug == true) console.debug('getUserReportListForUser log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The saved reports list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async runUserReportBase64Report({}, { userReportID }) {
    console.log('in runUserReportBase64Report...')
    try {
      const result = await kms.get(
        `/ReportManagement/UserReport/GetReportFile?userReportId=${userReportID}&asBase64=true`
      )

      if (isDebug == true) console.debug('runUserReportBase64Report log=' + JSON.stringify(result))

      return {
        returned: result
      }
    } catch (exception) {
      notifyProblem(`The saved report could not be retrieved.`)
      console.error(exception)
    }

    return {
      returned: ''
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const reportList = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
