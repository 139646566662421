<template>
  <PageContentLayoutOnly>
    <div>
      <h3 :style="{ display: 'inline-block' }">
        {{ `${reportCategoryName} ${$t('report.title')}` }}
      </h3>
    </div>

    <div class="columns" v-if="reportCategoryList && reportCategoryList != undefined">
      <div class="column is-5 pt-5">
        <label class="label">Report Category</label>
        <valid-select
          placeholder="Report Category"
          vid="reportCategory"
          rules="required"
          v-model="reportCategory"
        >
          <option v-for="option in reportCategoryList" :value="option" :key="option.value">{{
            option.label
          }}</option>
        </valid-select>
      </div>
    </div>

    <div
      v-if="columns && columns != undefined"
      :style="{ display: 'flex', flexDirection: 'column' }"
      class="container"
    >
      <div v-for="(column, index) in columns" :key="index" class="col">
        <div
          v-for="(item, index) in Object.keys(column)"
          :key="index"
          class="item-container pr-2 pt-2"
        >
          <div
            v-if="column[item] && column[item] != undefined"
            class="card card-width"
            :style="{
              padding: '1em'
            }"
          >
            <div class="media-left">
              <figure class="image is-48x48">
                <b-icon icon="file-document-outline" size="is-medium" />
              </figure>
            </div>

            <div class="media-content">
              <div
                class="is-12"
                :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }"
              >
                <label class="label">{{ `Name: ${column[item].name || ''}` }}</label>
                <b-button
                  class="button is-info is-light is-small is-outline is-rounded"
                  @click.prevent="processReport(column[item])"
                  >Run Report</b-button
                >
              </div>

              <div class="is-12">
                <label class="label" :style="{ margin: 0 }">{{ `Description: ` }}</label>
                <p>{{ column[item].description || '' }}</p>
              </div>

              <div
                v-if="
                  column[item].savedReports &&
                    column[item].savedReports != undefined &&
                    column[item].savedReports.length > 0
                "
                :style="{ display: 'flex', marginTop: '20px', justifyContent: 'space-between' }"
                class="is-9"
              >
                <valid-select v-model="userReport" vid="userReport" placeholder="Saved Reports">
                  <option
                    v-for="option in column[item].savedReports"
                    :value="option"
                    :key="option.value"
                    >{{ option.label }}</option
                  >
                </valid-select>

                <b-button
                  class="button is-info is-light is-small is-outline is-rounded"
                  @click.prevent="processSavedReport(userReport)"
                  >View</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal :toggle.sync="toggle">
      <MetaReportModal :toggle="toggle" :report="selectedReport"> </MetaReportModal>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Modal from '@/components/Modal'
import MetaReportModal from '@/components/MetaReportModal'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'

import { methods } from './keys/methods'
import { data } from './keys/data'

import _get from 'lodash/get'

import ValidSelect from '@/components/inputs/ValidSelect'

export default {
  name: 'Reports',
  components: {
    PageContentLayoutOnly,
    ValidSelect,
    Modal,
    MetaReportModal
  },

  data,

  computed: {
    ...mapGetters('user', ['authUser']),
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),

    columns: function() {
      let columns = []
      let count = 0

      if (this.reportTableList && this.reportTableList != undefined) {
        Array.from(this.reportTableList).forEach(element => {
          if (element && element != undefined) {
            if (element && element != undefined && element.name) {
              count += element.name.length
            }
          }
        })

        let objectKeys = Object.keys(this.reportTableList)
        if (objectKeys && objectKeys != undefined) {
          let totalLength = objectKeys.length + count
          totalLength = totalLength * 0.05

          let mid = Math.ceil(totalLength / this.cols)

          for (let col = 0; col < this.cols; col++) {
            columns.push(
              Object.entries(this.reportTableList)
                .slice(col * mid, col * mid + mid)
                .map(entry => entry[1])
            )
          }
        }
      }

      return columns
    }
  },

  watch: {
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
        this.reloadSavedReportList()
      }
    },
    reportCategory() {
      this.loadReportsByCategory(this.reportCategory)
    }
  },

  mounted() {
    this.filtered = this.$route.params.filtered

    if (this.isDebug == true)
      console.debug(
        _get(this, '$store.state.route.from.fullPath', '?') + ', filtered=' + this.filtered
      )

    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { report: { title: 'Reports' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

@media screen and (max-width: $tablet) {
  .filter-button {
    display: none;
  }
}

div.amountFrom {
  width: 60px !important;
}

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}
</style>
