/*
  import { metaReport } from '@/services/Reports/RunReport/Meta/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async runMetaReport({}, { pathWithParams }) {
    console.log('in runMetaReport...')
    try {
      const result = await kms.get(pathWithParams)

      if (isDebug == true) console.debug('runMetaReport log=' + JSON.stringify(result))

      return result
    } catch (exception) {
      notifyProblem(`The report could not be retrieved.`)
      console.error(exception)
    }

    return null
  },

  async runMetaBase64Report({}, { pathWithParams }) {
    console.log('in runMetaBase64Report...')
    try {
      const result = await kms.get(pathWithParams)

      if (isDebug == true) console.debug('runMetaBase64Report log=' + JSON.stringify(result))

      return {
        returned: result
      }
    } catch (exception) {
      notifyProblem(`The report could not be retrieved.`)
      console.error(exception)
    }

    return {
      returned: ''
    }
  },

  async runMetaReportAndSave({}, { pathWithParams }) {
    console.log('in runMetaReportAndSave...')
    try {
      const result = await kms.get(pathWithParams)

      if (isDebug == true) console.debug('runMetaReportAndSave log=' + JSON.stringify(result))

      return {
        userreportdto: result
      }
    } catch (exception) {
      notifyProblem(`The could not be saved and retrieved.`)
      console.error(exception)
    }

    return {
      userreportdto: null
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const metaReport = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
